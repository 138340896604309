import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import {
  Title,
  Text,
  Scrollable,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
  SimpleBlock
} from 'components';
import heroImg from '../images/azienda/01-lucchesi-azienda.jpg';
//import img02 from '../images/azienda/02-lucchesi-azienda.jpg';
import img02 from '../images/azienda/02-lucchesi-azienda-tessuto.jpg';
import img04 from '../images/azienda/04-emanuele-lucchesi.jpg';
import img05 from '../images/azienda/05-matteo-lucchesi.jpg';
import img06 from '../images/azienda/06-niccolo-lucchesi.jpg';
import img07 from '../images/azienda/07-lucchesi-azienda.jpg';
import img11 from '../images/azienda/11-lucchesi-azienda.jpg';
import img17 from '../images/azienda/17-lucchesi-azienda.jpg';
//import img12 from '../images/azienda/lucchesi_innovazione_annalisa.jpg';
const logos = [img02, img02, img02, img02, img02, img02, img02, img02, img02, img02, img02, img02];

const AziendaPage = ({ location }) => {
  const { t } = useTranslation();

  const PAGE_PROPERTIES = {
    title: (
      <Trans>
        <Title lower>
          Da sempre al fianco dei più <font className="text-secondary">grandi stilisti </font> del
          mondo
        </Title>
      </Trans>
    ),
    image: heroImg,
    description: (
      <Trans>
        I numerosi attestati di stima da parte dei principali stilisti, attraverso dediche e
        riconoscimenti, ci spronano ad investire in ogni direzione al fine di ispirare e sostenere
        il loro genio creativo
      </Trans>
    ),
  };

  const teamImages = [img04, img05, img06];

  return (
    <Layout location={location}>
      <SEO
        keywords={['Vivere in tessuto', 'Lucchesi']}
        title={t("Un'azienda tessile d'avanguardia.")}
        description={t("Ogni stagione le nostre collezioni si arricchiscono di novità tecniche legate alla tipologia di fibre impiegate, alla creatività dei tessuti proposti, ai finissaggi che soltanto noi siamo in grado di realizzare.")}
        location={location}
      />
      <section className="mt-12 md:mt-40">
        <Hero {...PAGE_PROPERTIES}></Hero>
      </section>
      <section className="my-24">
        <TShapedBlock
          invert
          title={t('Sviluppare idee insieme al cliente')}
          firstDesc={t(
            'La ragione di tanta attenzione nei nostri confronti, sta nella unicità dei nostri tessuti e nella straordinaria cura in termini di qualità e servizio che assicuriamo ai nostri clienti. ',
          )}
          secondDesc={t(
            'Ogni stagione le nostre collezioni si arricchiscono di novità tecniche legate alla tipologia di fibre impiegate, alla creatività dei tessuti proposti, ai finissaggi che soltanto noi siamo in grado di realizzare.',
          )}
          thirdDesc={t(
            'Lo stilista trova nella nostra azienda un riferimento fin dal primo momento del processo creativo, trovando in noi supporto nella ricerca e sperimentazione.',
          )}
          image={img02}></TShapedBlock>
      </section>
      <section className="mt-24 mb-96">
        <Scrollable
          title={
            <Trans>
              SIAMO LA <font className="text-secondary">QUARTA GENERAZIONE </font> DI UN’AZIENDA
              FONDATA NEL 1897
            </Trans>
          }
          label={t('Continuità')}
          images={teamImages}></Scrollable>
      </section>
      <div><br/></div>
      <section className="mt-24 h-screen w-screen relative flex flex-row items-center justify-center">
        <SplitTextBlock
          firstText={t('Trasformiamo tutti i giorni')}
          secondText={t('idee in progetti concreti')}
          label={t('VIVERE IN TESSUTO')}
          cta={t('vieni a scoprirlo')}
          ctaHref="/contatti"
          image={img07}></SplitTextBlock>
      </section>
      <div className="flex px-12 md:px-44 mt-24">
        <Text>
          {t(
            'Oggi la genialità di Marco Lucchesi viene rinnovata nel nostro lavoro: siamo i tre figli Emanuele, Matteo e Niccolò. Ogni giorno mettiamo il nostro impegno per portare alto il nome di nostro padre e della storia di nostra città, una storia appunto legata indissolubilmente all’eccellenza tessile. ',
          )}
        </Text>
      </div>

      <section className="flex flex-col md:flex-row py-16 md:px-44">
        <div className="w-2/5 md:w-1/5 self-start my-2 md:my-0">
          <StaticImage
            className="w-full h-full z-10"
            objectFit="contain"
            alt=""
            src="../images/azienda/08-lucchesi-azienda.jpg"></StaticImage>
        </div>
        <div className="md:w-3/5 mx-16 my-2 md:my-0">
          <StaticImage
            className="w-full h-full z-10"
            objectFit="contain"
            alt=""
            src="../images/azienda/09-lucchesi-azienda.jpg"></StaticImage>
        </div>
        <div className="w-1/3 md:w-1/5 self-end my-2 md:my-0">
          <StaticImage
            className="w-full h-full z-10"
            objectFit="contain"
            alt=""
            src="../images/azienda/10-lucchesi-azienda.jpg"></StaticImage>
        </div>
      </section>
      <div className="flex px-12 md:px-44 mb-16">
        <Text>
          {t(
            '“Vivere in tessuto” significa prima di tutto respirare questa quotidianità. Continuiamo a trasformare il potenziale delle idee che ruotano nelle nostre menti in progetti concreti, perché non smettiamo mai di “vivere in tessuto”.',
          )}
        </Text>
      </div>
      <section className="h-screen w-screen relative flex flex-row items-center justify-center">
        <SplitTextBlock
          firstText={t('I nostri valori vivono')}
          secondText={t('nei nostri tessuti')}
          image={img11}></SplitTextBlock>
      </section>
      <section className="my-32 md:my-16 ">
        <SecretsBlock />
      </section>
      {/* <section className="mt-40 mb-12 px-12 md:px-40">
        <div className="grid grid-cols-4 grid-rows-3 gap-x-4 md:gap-x-24 gap-y-12">
          {logos &&
            logos.map(logo => (
              <div className="h-12 w-auto">
                <img src={logo} className="w-full h-full"></img>
              </div>
            ))}
        </div>
      </section> */}
      <section className="my-24">
        <TShapedBlock
          cta={t('Leggi la storia')}
          title={t('Una storia lunga più di un secolo')}
          firstDesc={t(
            'I tempi sono cambiati, ma Lucchesi è ancora lì, nella stessa sede fondata da Guido Lucchesi, che ha accompagnato tutte le evoluzioni  della fabbrica e della moda dall’inizio del 900.',
          )}
          secondDesc={t(
            'Questo è il nostro segreto: una storia di innovazione che si rinnova di generazione in generazione, lasciando da oltre un secolo la nostra impronta sulle migliori collezioni di tutto il mondo.',
          )}
          image={img17}></TShapedBlock>
      </section>
      <section className="my-12 md:px-12 text-left">
        <div className="px-12 flex flex-col">
          <div className="my-12 uppercase">
            <Text serif>{t('La sede di Prato')}</Text>
          </div>
          <Title size="hero">
            {t('Un posto unico nel mondo, ricco di tesori tutti da scoprire')}
          </Title>
        </div>
      </section>
      <section className="flex md:my-40 md:h-screen justify-center items-center">
        <StaticImage
          className="object-cover"
          src="../images/azienda/16-lucchesi-azienda.jpg"></StaticImage>
      </section>
    </Layout>
  );
};

export default AziendaPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
